import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Side Projects" mdxType="Title" />
    <span id="projects"></span>
    <h5><a parentName="h5" {...{
        "href": "https://play.google.com/store/apps/details?id=elvis10ten.com.past_questions&hl=en_US&gl=US"
      }}>{`Prepup`}</a></h5>
    <p>{`Prepup has helped over 120,000 high school students in West Africa prepare for standardized tests.`}</p>
    <div className="video_wrapper">
    <iframe className="video_frame" width="100%" height="100%" src="https://www.youtube.com/embed/WUGaB5e83wg?start=58" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <hr></hr>
    <h5><a parentName="h5" {...{
        "href": "https://github.com/Elvis10ten/pcas"
      }}>{`PCAS`}</a></h5>
    <p>{`Peripheral connection augmentation system (PCAS) artificially augments a Bluetooth peripheral maximum number of concurrent connections.`}</p>
    <div className="video_wrapper">
    <iframe className="video_frame" width="100%" height="100%" src="https://www.youtube.com/embed/WMmtQLNYF-g" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <hr></hr>
    <h5><a parentName="h5" {...{
        "href": "https://github.com/Elvis10ten/dart"
      }}>{`DART`}</a></h5>
    <p>{`Distributed Android Remote Testing (DART) makes it possible to run automated instrumentation tests on any remote Android device without ADB.`}</p>
    <div className="video_wrapper">
    <iframe className="video_frame" width="100%" height="100%" src="https://www.youtube.com/embed/7SotlhlwMDA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <hr></hr>
    <h5><a parentName="h5" {...{
        "href": "https://play.google.com/store/apps/details?id=com.appcore.fxmusicplayer&hl=en_US&gl=US"
      }}>{`Fx Music Player`}</a></h5>
    <p>{`A fully customizable Android music player with audio discovery.`}</p>
    <p><img parentName="p" {...{
        "src": "./fx_music_player.png",
        "alt": "Fx Music Player Screenshot"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      