import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi 👋🏻
    </Text>
    <p>{`I'm Elvis Chidera — currently a staff software engineer at `}<a parentName="p" {...{
        "href": "https://deliveryhero.com/about/"
      }}>{`DeliveryHero`}</a>{`. I'm 25 and originally from 🇳🇬, but presently in Berlin, 🇩🇪. Lived in the 🇺🇸 on an O1 visa. I'm an entrepreneur at heart and enjoy shipping projects on the weekends.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      